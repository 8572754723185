/**
 * Replace the smaller subset of lodash feature we really need.
 * See more at https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore.
 */

export function get (obj: any, path: any, defaultValue: any = undefined) {
  const travel = (regexp: any) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res: any, key: any) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
}

export function debounce<F extends Function>(func: F, wait: number, immediate = false): F {
  let timeout: number;

  return function() {
    // @ts-ignore
    const context = this, args = arguments;

    return new Promise((resolve) => {
      clearTimeout(timeout);
      if (immediate && !timeout) resolve(func.apply(context, args));

      timeout = window.setTimeout(function() {
        timeout = null;
        if (!immediate) resolve(func.apply(context, args));
      }, wait);
    })
  } as any
}

export function onlyUniqueByReference (value: any, index: number, self: any[]) { 
  return self.indexOf(value) === index;
}

export function onlyUniqueByValue (value: any, index: number, self: any[]) {
  return self.findIndex(_ => deepEqualLite(_, value)) === index;
}

export function onlyUniqueByTransform<T> (transform: (arg: T) => any) {
  return function (value: T, index: number, self: T[]) { 
    return self.findIndex(_ => transform(_) === transform(value)) === index;
  }
}

export function toUpperCaseFirst (str: string) {
  return str ? str[0].toUpperCase() + str.substring(1) : '';
}

export function intersection<T> (arrays: T[][]) {
  return arrays.length ? arrays.reduce((a, b) => a.filter(c => b.includes(c))) : [];
}

/**
 * Take items in array until `accumulator` returns false.
 * First argument of `accumulator` is an accumulated value, initilized with `initialValue`.
 */
export function takeWhile<T, U> (array: T[], accumulator: (acc: U, curr: T, index: number, array: T[]) => U | false, initialValue: U) {
  const taken = [];
  let currentValue: U | false = initialValue;

  for (let i = 0; i < array.length; i++) {
    currentValue = accumulator(currentValue, array[i], i, array);
    if (currentValue === false) return taken;
    taken.push(array[i])
  }
  
  return taken;
}

/**
 * Based on https://github.com/lukeed/dequal/blob/master/src/lite.js
 * 
 * See https://github.com/lukeed/dequal for available types comparisons.
 */
export function deepEqualLite (foo: any, bar: any) {
	let ctor, len;
	if (foo === bar) return true;

	if (foo && bar && (ctor=foo.constructor) === bar.constructor) {
		if (ctor === Date) return foo.getTime() === bar.getTime();
		if (ctor === RegExp) return foo.toString() === bar.toString();

		if (ctor === Array) {
			if ((len=foo.length) === bar.length) {
				while (len-- && deepEqualLite(foo[len], bar[len]));
			}
			return len === -1;
		}

		if (!ctor || typeof foo === 'object') {
			len = 0;
			for (ctor in foo) {
				if (Object.prototype.hasOwnProperty.call(foo, ctor) && ++len && !Object.prototype.hasOwnProperty.call(bar, ctor)) return false;
				if (!(ctor in bar) || !deepEqualLite(foo[ctor], bar[ctor])) return false;
			}
			return Object.keys(bar).length === len;
		}
	}

	return foo !== foo && bar !== bar;
}