import * as React from 'react'

interface Props { 
  src?: string
  initials?: string
  className?: string
  style?: React.CSSProperties
}

export default function AvatarComponent ({ src, initials, className = '', style = {} }: Props) {
  return <span className={ `avatar ${src ? '' : 'avatar--placeholder'} ${className}` } style={ style }>
    { src && <img src={ src } loading="lazy" style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" /> }
    { !src && initials }
  </span>
}
