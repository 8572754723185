import { useState, useLayoutEffect } from 'react'

export function useMediaQuery (mediaQuery: string) {
  const [matched, setMatched] = useState<boolean>(window.matchMedia(mediaQuery).matches)

  useLayoutEffect(() => {
    const resizeListener = () => {
      const newMatched = window.matchMedia(mediaQuery).matches
      if (newMatched !== matched) setMatched(newMatched)
    }

    window.addEventListener('resize', resizeListener)
    return () => window.removeEventListener('resize', resizeListener)
  }, [mediaQuery, matched])

  return matched
}
