import * as React from 'react'
import { httpGetRoute } from '../../services/utils/http.service'
import { debounce } from '../../services/utils/lodash.service'
import MenuSideComponent from './menu-side.component'
import { useOnResize } from '../../hooks/use-on-resize'
import MenuPrimaryInHeaderComponent from './menu-primary-in-header.component'

interface Props { menu: Menu }
export interface MenuItem { href: string, icon: string, text: string, active: boolean, className?: string, count?: number }
export interface MenuItemCollection { items: MenuItem[], accountItems: MenuItem[] }

export default function MenuComponent ({ menu, menu: { logo, baseline, customer, messaging } }: Props) {
  const shouldUseCompactLayout = React.useCallback(() => {
    return window.innerWidth < 1200 || location.href.includes('join_meeting') || window.currentUser?.type === 'Operator'
  }, [])

  const [opened, setOpened] = React.useState(false)
  const [compactLayout, etCompactLayout] = React.useState(shouldUseCompactLayout())
  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight)

  const menuRef = React.useRef<HTMLDivElement>(null)

  useOnResize(() => debounce(() => {
    etCompactLayout(shouldUseCompactLayout())
    setInnerHeight(window.innerHeight)
  }, 50)(), [shouldUseCompactLayout])

  // Put the generated HTML in cache. It will be shown on next navigation until react got initialized.
  React.useEffect(() => { localStorage.setItem('cacheMenu', menuRef.current.innerHTML) })

  const homePath = React.useMemo(() => {
    return menu.employee?.candidateWithLimitedAccess ? `/${httpGetRoute()}/principal_information` : `/${httpGetRoute()}`
  }, [menu.employee?.candidateWithLimitedAccess])

  return <div ref={ menuRef }>
    <MenuSideComponent
      menu={ menu }
      innerHeight={ innerHeight }
      compactLayout={ compactLayout }
      homePath={ homePath }
      opened={ opened }
      setOpened={ setOpened }/>

    <header className="headerMain" role="banner" id="header">
      <div className="containerPage-inner" style={{ display: 'flex', alignItems: 'center' }}>
        { /** hamburger menu icon on the left*/ }
        { compactLayout && <a className="menu-icon icon-menu" onClick={ () => setOpened(!opened) }></a>  }

        { /** the market logo, visible on large screens */ }
        <a href={ homePath } className="hidden-xs"><img className="u-mls" src={ logo.url } alt={ logo.alt } /></a>
        { window.currentUser.type === 'Employee' && <a className="u-mrs visible-xs icon-home menu-icon" href={ homePath }></a> }

        { /** Operators have an additional menu on top of the desktop screen while users have the baseline instead */ }
        { window.currentUser.type !== 'Operator' && <p className="headerMain-baseline hidden-xs">{ baseline }</p> }
        
        { /** clocking (for employee) or new casting (customer) button. This button is centered on phones and small tablettes */ }
        <span style={{ flexGrow: 1 }}></span>
        <MenuPrimaryInHeaderComponent menu={ menu } />

        <span className="visible-xs-block" style={{ flexGrow: 1 }}></span>

        { /** icons on the right, including customerFollowUpLiaisonBook (for customer), messaging and cart (for customer) */ }
        { customer.followUpLiaisonBook?.active && <a className="u-mrs icon-address-book menu-icon" href={ customer.followUpLiaisonBook.url }>
          { !!customer.followUpLiaisonBook.count && <span className="menu-icon-count">{ customer.followUpLiaisonBook.count }</span> }
        </a> }

        { messaging?.active && <a className="u-mrs icon-messaging menu-icon" href={ `/${httpGetRoute()}/messaging_conversations` }>
          { !!messaging.count && <span className="menu-icon-count">{ messaging.count }</span> }
        </a> }

        { customer.cart?.active && <a className="u-mrs icon-shopping-cart menu-icon" href={ customer.cart.url }>
          { !!customer.cart.count && <span className="menu-icon-count">{ customer.cart.count }</span> }
        </a> }
      </div>
    </header>
  </div>
}
