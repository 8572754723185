import * as React from 'react'
import { I18nService } from '../../services/utils/i18n.service'
import { httpGetRoute } from '../../services/utils/http.service'

interface Props { menu: Menu }
const i18nScoped = new I18nService(require('./menu-primary-in-header.component.yml'))
const LazyClockingButtonComponent = React.lazy(() => import('../employees/clockings/clocking-button.component'))

export default function MenuPrimaryInHeaderComponent ({ menu: { employee, customer,  backoffice, market } }: Props) {
  return <>
    { employee.clockings && !employee.candidateWithLimitedAccess && <React.Suspense fallback={ null }><LazyClockingButtonComponent /></React.Suspense> }
        
    { (customer.newCasting || employee.newCasting) && <a
      className="u-mrm u-mlm button button--S"
      href={ `/${window.market_type.toLocaleLowerCase()}s/${window.market_id}/castings/new` }
      children={ i18nScoped.t('new_casting') } /> }

    { window.currentUser.type === 'Operator' && window.market_type === 'Supplier' && <div className="headerMain-middle">
      <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/castings` }>{ i18nScoped.t('castings') }</a>

      <a className="headerMain-middle-link" style={{ position: 'relative' }} href={ `/${httpGetRoute()}/plannings` }>
        { i18nScoped.t('events_operator') }
        { backoffice.anyIncomingEventToUpdate && <span className="menu-icon-count" style={{ position: 'static', height: '1.3em', marginLeft: '0.4em' }} /> }
      </a>

      <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/customers` }>{ i18nScoped.t('customers') }</a>
      
      { backoffice.soloEdition
        ? <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/invoices` }>{ i18nScoped.t('invoicing') }</a>
        : <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/employees` }>{ i18nScoped.t('employees') }</a> }

      { backoffice.adminSupplier && <a
        className="headerMain-middle-link"
        href={ `/${httpGetRoute()}/offered_services` }
        children={ i18nScoped.t('offered_services_and_params') } /> }
    </div> }

    { window.currentUser.type === 'Operator' && window.market_type === 'Marketplace' && <div className="headerMain-middle">
      { market.marketplaceType === 'standart' && <>
        <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/castings` }>{ i18nScoped.t('castings') }</a>
        <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/customers` }>{ i18nScoped.t('customers') }</a>

        <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/memberships` }>
          { i18nScoped.t('suppliers') }
          { !!market.ogustineConnectNewSupplierCount && <span className="u-positionRelative menu-icon-count" style={{ top: '-0.3em', fontSize: '0.9rem' }}>{ market.ogustineConnectNewSupplierCount }</span> }
        </a>

        <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/membership_prescribers` }>{ i18nScoped.t('prescribers') }</a>

        { market.adminSupplier && <a
          className="headerMain-middle-link"
          href={ `/${httpGetRoute()}/offered_services` }
          children={ i18nScoped.t('offered_services_and_params') } /> }
      </> }

      { market.marketplaceType === 'independant' && <>
        <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/invoices` }>{ i18nScoped.t('invoicing') }</a>
        <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/memberships` }>{ i18nScoped.t('suppliers') }</a>
        <a className="headerMain-middle-link" href={ `/${httpGetRoute()}/offered_services` }>{ i18nScoped.t('offered_services_and_params') }</a>
      </> }
    </div> }
  </>
}
